<template>
  <div class="bao-background d-flex p-3 justify-content-center">
    <div v-if="loading">
      <progress-indicator></progress-indicator>
    </div>

    <div v-else
         class="input-block"
    >
      <!--  icons/logo  -->
      <div class="d-flex w-100 justify-content-center my-n3">
        <div class="d-flex align-items-center">
          <img src="../../../public/img/bao_logos/baoLogoCentetred_free.png"
               class="h-130px mr-2"
               alt="bao solutions"
          >

          <div class="vertical-center">
            <i class="fas fa-exchange-alt"></i>
          </div>

          <img src="../../../public/img/crm_logos/sugarcrm.svg"
               class="h-80px"
          >
        </div>
      </div>
      <div v-show="warning"></div>
      <b-alert v-if="error || warning"
               dismissible
               :variant="warning ? 'warning': 'danger'"
               show
               @dismissed="resetMessages()"
      >
        {{error}}
        {{warning}}
      </b-alert>

      <!--  site url and platform  -->
      <b-form-group v-if="!clientSiteInfoExists">
        <label for="site-url-input">
          {{ staticText.siteUrlLabel }}
          <i id="site-url-input-help"
             class="far fa-question-circle fa-lg cursor-pointer"
          ></i>
          <b-popover target="site-url-input-help"
                     custom-class="bao-popover"
                     triggers="hover"
          >
            {{ staticText.siteUrlHelpText }}
          </b-popover>
        </label>
        <b-form-input id="site-url-input"
                      type="url"
                      :placeholder="staticText.siteUrlPlaceholderLabel"
                      :state="validateUrl()"
                      v-model="siteUrl"
        ></b-form-input>

        <label for="platform" class="mt-3">
          {{ staticText.platformLabel }}
          <i id="platform-input-help"
             class="far fa-question-circle fa-lg cursor-pointer"
          ></i>
          <b-popover target="platform-input-help"
                     custom-class="bao-popover"
                     triggers="hover"
          >
            {{ staticText.platformHelpText }}
          </b-popover>
        </label>
        <b-form-input id="platform"
                      type="text"
                      :state="checkWarnings()"
                      v-model="platform"
        ></b-form-input>

      </b-form-group>

      <!--  username/password  -->
      <b-form-group>
        <label for="username">
          {{ staticText.usernameLabel }}
        </label>
        <b-form-input id="username"
                      type="text"
                      required
                      v-model="username"
        ></b-form-input>

        <label for="password" class="mt-3">
          {{ staticText.passwordLabel }}
        </label>
        <b-form-input id="password"
                      type="password"
                      required
                      v-model="password"
        >
        </b-form-input>
      </b-form-group>
      <b-btn class="d-flex w-50 justify-content-center mx-auto"
             type="submit"
             @click.prevent="handleSubmit"
      >
        {{ staticText.connectLabel }}
      </b-btn>

    </div>
  </div>
</template>

<script>
import axios from "axios"
import ProgressIndicator from "@/apps/base/ProgressIndicator"

export default {
  name: "SugarCrmLogin",
  components: { ProgressIndicator },
  data () {
    return {
      axios,
      siteUrl: null,
      platform: null,
      version: null,
      username: null,
      password: null,
      clientSiteInfoExists: false,
      error: null,
      warning: null,
      loading: false,
      staticTextDefault: {
        connectLabel: "Connect",
        siteUrlEmptyError: "URL is empty.",
        invalidSiteUrlError: "URL is invalid.",
        platformEmptyWarning: "Platform is empty. If you do not provide this value, 'base' will be automatically selected and you will be logged out from any other system on this platform.",
        passwordEmptyError: "Password is empty.",
        usernameEmptyError: "Username is empty.",
        usernameLabel: "Username",
        passwordLabel: "Password",
        siteUrlLabel: "URL",
        siteUrlPlaceholderLabel: "Enter the URL of your SugarCRM",
        siteUrlHelpText: "Enter the URL of your SugarCRM. It’s the same URL how you access your SugarCRM via the browser.",
        platformLabel: "Platform",
        platformHelpText: "Please contact your admin or bao customer success manager if you don’t know the platform.",
        unableToAuthenticateError: "Unable to authenticate. Please check your credentials."
      }
    }
  },
  mounted () {
    this.checkExistingSiteInfo()
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    checkEmptyInputErrors () {
      if (!this.username) return this.staticText.usernameEmptyError
      if (!this.password) return this.staticText.passwordEmptyError
      if (!this.clientSiteInfoExists && !this.siteUrl) return this.staticText.siteUrlEmptyError
      return null
    }
  },
  methods: {
    resetMessages () {
      this.error = null
      this.warning = null
    },
    checkExistingSiteInfo () {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.axios.get("/api/oauthlogin/sugarcrm_check_site_info").then(response => {
          if (response.data) this.clientSiteInfoExists = true
          resolve(response.data)
          this.loading = false
        }).catch(error => {
          this.error = error.response
          reject(error.response)
          this.loading = false
        })
      })
    },
    handleSubmit () {
      this.error = this.checkEmptyInputErrors
      if (!this.clientSiteInfoExists && !this.isValidUrl(this.siteUrl)) this.error = this.staticText.invalidSiteUrlError
      if (this.error) return

      const data = {
        username: this.username,
        password: this.password,
        state: this.$route.query.state,
        site_url: this.siteUrl,
        platform: this.platform,
        code: 1 // this can be any value other than null
      }
      this.loading = true
      const url = "/api/oauthlogin/retrieve_access_token"
      this.axios.put(url, data).then(response => {
        this.loading = false
        window.close()
      }).catch(error => {
        this.error = error.response.data.error
        if (this.error === "Valid site URL is required.") {
          this.clientSiteInfoExists = false
        } else this.error = this.staticText.unableToAuthenticateError
        this.loading = false
      })
    },
    isValidUrl (urlString) {
      let url
      try {
        url = new URL(urlString)
      } catch (e) {
        return false
      }
      return url.protocol === "http:" || url.protocol === "https:"
    },
    validateUrl () {
      if (this.siteUrl === null) return null
      if (this.siteUrl === "") return false
      return this.isValidUrl(this.siteUrl)
    },
    checkWarnings () {
      if (this.platform === null) return null
      if (!this.clientSiteInfoExists && this.platform === "") {
        this.warning = this.staticText.platformEmptyWarning
        return false
      }
      this.warning = null
      return true
    }
  }
}
</script>

<style scoped lang="scss">

.h-80px {
  height: 80px;
}

.h-130px {
  height: 130px;
}

.vertical-center {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.bao-background {
  background: $background;
  height: 100%;
}

.input-block{
  display: inline-block;
  width: 350px;
}

</style>
